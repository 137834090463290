<template lang="pug">
div
  #upload-deliveries-from-excel()
    .loading(v-if="loading")
      .text-center.mb-2 {{ loadingText }}
      b-spinner.m-auto
    div(v-else-if="step===1").text-center.pt-2
      input.d-none(ref="inputFile" type="file" name="file" @change="setFile")
      b-button(@click="() => this.$refs.inputFile.click()") Cargar archivo
        .material-icons upload
      .help-text.mt-1
        span Para identificar si un archivo es de retiro o envío, su nombre debe incluir la palabra "retiro" o "envío".
    div(v-else-if="step===2")
      .mb-2 Se encontraron varias paginas, selecciona las que deseas ingresar y haz click en Siguiente
      b-form-checkbox-group(v-model="sheets" :options="sheetsOptions" stacked)
      b-button(@click="step=3" :disabled="!sheets.length") Siguiente
    div(v-else-if="step===3")
      div Se {{ deliveries.length===1 ? 'cargó' : 'cargaron' }} correctamente {{deliveries.length}} {{ deliveries.length===1 ? 'envío' : 'envíos' }}
      div.text-danger(v-if="errorCount > 0") {{errorCount}} datos nulos:
      div.error-messages(v-if="deliveryErrors.length > 0")
        span.error-row.text-danger(v-for="error in deliveryErrors") {{ `Error en fila ${error.row}, Columna: ${error.column}, Cod. Seguimiento: ${error.drivInCode}. Error: ${errorText[error.error] || error.error}` }}

    div(v-else-if="step===4")
      div {{ loadingText }}
      div(v-if="resultsText") {{ resultsText }}
</template>

<script>
import xlsxFile from 'read-excel-file'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      loadingText: 'Cargando archivo',
      resultsText: null,
      tagsNames: {
        flex: 'flex',
        cambio: 'exchange',
        devolución: 'return'
      },
      step: 1,
      file: null,
      sheets: [],
      sheetsOptions: [],
      loading: false,
      deliveries: [],
      errorCount: 0,
      stores: [],
      drivers: [],
      driversToEmit: [],
      storesToEmit: [],
      deliveryRegex: /[eE][nN][vV][iIíÍ][oOóÓ]/,
      withdrawalRegex: /[rR][eEéÉ][tT][iIíÍ][rR][oOóÓ]/,
      errorText: {
        required: 'no puede estar vacío'
      },
      schema: {},
      deliverySchema: {
        'Código tienda': {
          prop: 'internalCode',
          type: String
        },
        'CODIGO DE SEG': {
          prop: 'drivInCode',
          type: String,
          required: true
        },
        PROOVEDOR: {
          prop: 'supplierName',
          type: String
        },
        TIENDA: {
          prop: 'customerName',
          type: String,
          required: true
        },
        'NOMBRE DE SU CLIENTE': {
          prop: 'finalCustomer',
          type: String,
          required: true
        },
        TELEFONO: {
          prop: 'contactPhone',
          type: String
        },
        'DIRECCION DE SU CLIENTE': {
          prop: 'address',
          type: String,
          required: true
        },
        COMUNA: {
          prop: 'commune',
          type: String,
          required: true
        },
        OBSERVACIONES: {
          prop: 'observations',
          type: String
        },
        CORREO: {
          prop: 'contactEmail',
          type: String
        },
        TAGS: {
          prop: 'tags',
          type: String
        },
        'COD. REPARTIDOR': {
          prop: 'codeDriver',
          type: String
        }
      },
      withdrawalSchema: {
        FECHA: {
          prop: 'date',
          type: String,
          pattern: '^\\d{2}/\\d{2}/\\d{4}$',
          // required: true
          required: false
        },
        'Nª TIENDA': {
          prop: 'internalCode',
          type: String
        },
        'CODIGO DE SEG': {
          prop: 'drivInCode',
          type: String,
          required: true
        },
        PROVEEDOR: {
          prop: 'supplierName',
          type: String
        },
        TIENDA: {
          prop: 'customerName',
          type: String,
          required: true
        },
        ENCARGADO: {
          prop: 'finalCustomer',
          type: String
        },
        TELEFONO: {
          prop: 'contactPhone',
          type: String
        },
        Dirección: {
          prop: 'address',
          type: String
        },
        COMUNA: {
          prop: 'commune',
          type: String
        },
        'OBSERVACIONES (REP)': {
          prop: 'observations',
          type: String,
          required: true
        },
        'COD. REPARTIDOR': {
          prop: 'codeDriver',
          type: String
        }
      }
    }
  },
  methods: {
    ...mapActions(['bulkCreateDelivery', 'checkWithdrawalHour', 'listExceptions', 'listAllStoreName', 'listDriver']),
    async onSubmit () {
      if (this.loading) return
      this.loading = true
      this.loadingText = 'Registrando los envíos'
      console.log(this.deliveries, 'deli')
      const response = await this.bulkCreateDelivery({ data: this.deliveries })
      if (response.status === 200) {
        const updateData = { drivers: [...new Set([...this.driversToEmit, ...response.data.updated.drivers])], stores: [...new Set([...this.storesToEmit, ...response.data.updated.stores])] }
        this.makeSuccessToast('Los envíos se registraron correctamente')
        this.loadingText = 'Los envíos se registraron correctamente'
        this.resultsText = `Resultados: Actualizados: ${response.data.counters.updated}  -  Creados: ${response.data.counters.created}`
        this.$emit('done', updateData)
      } else if (response.status >= 400) {
        this.loadingText = `Error al registrar envíos. ${response.data.message || response.data}`
        this.makeDangerToast(response.data.message || response.data || 'Error al crear los envios')
      }

      this.step = 4
      this.loading = false
    },
    async fetchDrivers () {
      const response = await this.listDriver()
      this.drivers = response.data
    },
    setFile (event) {
      this.file = event.target.files[0]
    },
    setSchema (fileName) {
      // Busca si fileName contiene la palabra 'retiro' (independiente de mayúsculas, minúsculas o acentos)
      // y establece el schema para retiros, sino establece el schema para envíos por defecto
      if (this.withdrawalRegex.test(fileName)) {
        this.schema = this.withdrawalSchema
      } else {
        this.schema = this.deliverySchema
      }
    },
    getStoreData (delivery) {
      const stores = this.stores.filter((s) => delivery.internalCode ? delivery.internalCode.toUpperCase() === s.internalCode?.toUpperCase() : delivery.customerName?.toUpperCase() === s.name?.toUpperCase())
      let store = null

      if (stores.length > 1) {
        store = stores.find((s) => !!s.internalCode)
        if (!store) {
          store = stores[0]
        }
      } else if (stores.length === 1) {
        store = stores[0]
      } else {
        return null
      }

      return { ...store, commune: store.commune?.name || '' }
    },
    getDriver (delivery) {
      const driver = this.drivers.filter((d) => d.codeDriver?.toUpperCase() === delivery.codeDriver?.toUpperCase())
      return driver.length ? { id: driver[0].id, driver: `${driver[0].name} ${driver[0].lastName}` } : { driver: null, id: null }
    }
  },
  computed: {
    ...mapGetters(['user', 'exceptions'])
  },
  async created () {
    const stores = await this.listAllStoreName()
    this.stores = stores
    const drivers = await this.listDriver()
    this.drivers = drivers.data
  },
  mounted () {
    this.$emit('showSaveBtn', false)
  },
  watch: {
    async file () {
      this.loading = true
      const sheets = await xlsxFile(this.file, { getSheets: true })
      this.sheetsOptions = sheets.reduce((acum, item) => acum.concat({
        value: item.name,
        text: item.name
      }), [])
      this.step = 2
      this.loading = false
    },
    async step () {
      if (this.step === 2 && this.sheetsOptions.length === 1) {
        this.sheets = [this.sheetsOptions[0].value]
        this.step = 3
      }
      if (this.step === 3) {
        if (!this.loading) {
          this.loading = true
          let deliveries = []
          let count = 0
          this.loadingText = `Cargando plantilla (${count} / ${this.sheets.length})`
          this.setSchema(this.file.name)

          for (const sheet of this.sheets) {
            const { rows, errors } = await xlsxFile(this.file, { schema: this.schema, sheet })
            // Asigna número de row a rows sin errores para una mejor localización en caso de necesitarlo
            const errorRows = new Set(errors.map(error => error.row))
            const validRowIndices = rows.map((row, index) => !errorRows.has(index + 1) ? index + 2 : null).filter(index => index !== null)
            // console.log(validRowIndices)
            const validRows = rows.map((row, index) => {
              return {
                ...row,
                row: validRowIndices[index]
              }
            })

            // console.log(rows, 'ROWS', errors, 'ERRORS')
            deliveries = deliveries.concat(validRows)
            const deliveryDate = this.$moment(new Date(new Date().setDate(new Date().getDate() + 0))).format('YYYY-MM-DD')
            // Filtra los rows vacíos o con un valor error de #ERROR_#N/A
            const deliveriesFiltered = deliveries.filter(d => Object.values(d).some(value => value && !['#ERROR_#N/A'].includes(value)))
            // console.log(deliveriesFiltered, 'FILTERED')
            const errorData = []
            deliveries = deliveriesFiltered.map(d => {
              if (d.driverId) this.driversToEmit.push(d.driverId)
              if (d.customerName) this.storesToEmit.push(d.customerName)
              const storeData = this.getStoreData(d)
              if (!storeData) {
                errorData.push({ ...d, error: 'No se encontró la tienda', column: 'TIENDA' })
              }
              return {
                ...d,
                date: d.date ? d.date.split('/').reverse().join('-') : deliveryDate,
                storeId: storeData ? storeData.id : null,
                driverId: this.getDriver(d).id,
                driver: this.getDriver(d).driver,
                finalCustomer: d.finalCustomer || (this.schema.ENCARGADO && storeData ? storeData.withdrawalAdgent : ''),
                contactPhone: d.contactPhone || (this.schema.ENCARGADO && storeData ? storeData.pickupPhone1 : ''),
                address: d.address || (this.schema.ENCARGADO && storeData ? storeData.withdrawalAddress : ''),
                commune: d.commune || (this.schema.ENCARGADO && storeData ? storeData.commune : ''),
                tags: d.tags ? this.tagsNames[d.tags.toLowerCase()] : null

              }
            })
            count++
            this.loadingText = `Cargando plantilla (${count} / ${this.sheets.length})`
            // console.log(errors, 'este es el error')
            const errorsFiltered = [...errorData]
            for (const error of errors) {
              // if (errors.filter((e) => e.row === error.row).length < Object.values(this.schema).filter((value) => value.required).length) {
              if (deliveriesFiltered[error.row - 1]) {
                errorsFiltered.push(error)
              }
            }

            this.errorCount += errorsFiltered.length
            this.deliveryErrors = errorsFiltered
            if (!errorsFiltered.length) {
              this.$emit('errors', false)
            } else {
              this.$emit('errors', true)
            }
          }
          if (!deliveries.length) {
            this.$emit('errors', true)
          }
          if (deliveries.length > 0) {
            this.$emit('showSaveBtn', true)
          }
          this.deliveries = deliveries
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#upload-deliveries-from-excel {
  .loading {
    display: flex;
    justify-content: center;
    height: 200px;
    flex-direction: column;
  }
}
.error-row {
  display: flex;
  justify-content: start;
  padding-left: 2rem;
}
</style>
